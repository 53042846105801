import styled from 'styled-components';
import { COLORS, BREAKPOINTS } from '../../utils/theme';

export const HomeCAN = styled.section`
  position: relative;
`;

export const HomeCANContainer = styled.section`
  display: flex;
  flex-direction: row;
  padding: 80px 60px 180px;
  background-color: ${COLORS.WHITE};

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    flex-direction: column;

    padding: 0 20px 140px;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding: 0 20px 100px;
  }
`;

export const HomeCANCol = styled.div`
  flex: 1;
  position: relative;

  &:first-child {
    flex: 0;
    min-width: 720px;
    @media (max-width: ${BREAKPOINTS.TABLET}px) {
      min-width: initial;
    }
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const HomeCANBigTitle = styled.h2`
  margin: 0;
  font-size: 104px;
  font-weight: 600;
  color: ${COLORS.BLACK};
  max-width: 480px;
  line-height: 98px;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    display: none;
  }
`;

export const HomeCANVideoImg = styled.div`
  position: absolute;
  width: 540px;
  height: 305px;
  top: 310px;
  left: 55px;
  overflow: hidden;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    position: relative;
    margin-top: -50px;
    top: initial;
    left: initial;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 335px !important;
    height: 188px !important;
  }

  > div {
    width: 100%;
    height: 100%;
  }
`;

export const HomeCANSmallTitle = styled.h6`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: ${COLORS.BLACK};
  margin-top: 70px;
  margin-bottom: 10px;
  opacity: 0.9;
`;

export const HomeCANDesc = styled.p`
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  color: ${COLORS.BLACK};
  max-width: 600px;
  font-style: normal;
  line-height: 1.55;
  opacity: 0.9;

  & a {
    color: inherit;
  }

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    text-align: center;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    max-width: initial;
    font-size: 16px;
    line-height: 22px;
  }
`;
