import styled from 'styled-components';
import { COLORS, BREAKPOINTS } from '../../utils/theme';

import TextureOrange from '../../images/textures/orange-2.png';
import TextureBlue from '../../images/textures/blue-1.png';

export const Container = styled.section`
  position: relative;
`;

export const HomeMatchmakerContainer = styled.div`
  padding: 2.5rem 1.25rem;
  box-shadow: inset 0px 32px 64px 0px rgba(0, 0, 0, 0.05);

  position: relative;
  overflow: hidden;

  isolation: isolate;

  @media (min-width: ${BREAKPOINTS.PHABLET}px) {
    padding-left: 1.75rem;
    padding-right: 1, 75rem;
  }
  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  &::before {
    content: '';
    position: absolute;
    right: -4rem;
    top: -1rem;
    bottom: 0;
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('${TextureOrange}');
    height: 100%;
    width: 35vw;
  }
  &::after {
    content: '';
    position: absolute;
    right: -7rem;
    top: 0;
    bottom: 0;
    background-position: bottom right;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('${TextureBlue}');
    height: 100%;
    width: 60vw;
  }

  @media (min-width: ${BREAKPOINTS.PHABLET}px) {
    &::before {
      height: 85%;
      top: -4rem;
      right: -2rem;
    }
    &::after {
      top: 6rem;
      right: -4rem;
    }
  }
`;

export const HomeMatchmakerSectionTitle = styled.h2`
  font-weight: 500;
  font-size: 0.875rem;
  text-transform: uppercase;
`;
export const HomeMatchmakerTitle = styled.h3`
  font-weight: 600;
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  b {
    font-weight: 600;
    color: ${COLORS.BLUE};
  }

  max-width: min(40rem, 60vw);

  @media (min-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 2.5rem;
  }
`;
export const HomeMatchmakerLink = styled.a`
  display: inline-block;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1;
  color: ${COLORS.BLUE};
  text-decoration: none;
  border: 1px solid ${COLORS.BLUE};
  border-radius: 2rem;
  padding: 0.5rem 1rem 0.6rem;
`;
