import React from 'react';

import { Logo, MenuButton } from '../../utils/theme';

import {
  Container,
  HomeMatchmakerContainer,
  HomeMatchmakerSectionTitle,
  HomeMatchmakerTitle,
  HomeMatchmakerLink,
} from './styles';

const HomeMatchmakerBanner = (props) => {
  return (
    <Container>
      <Logo />
      <MenuButton menuIsShown={props.menuIsShown} />
      <HomeMatchmakerContainer>
        <HomeMatchmakerSectionTitle>Matchmaker</HomeMatchmakerSectionTitle>
        <HomeMatchmakerTitle>
          Discover the <b>Matchmaker</b> to find and get in touch with <b>potential allies</b>{' '}
          within our network.
        </HomeMatchmakerTitle>
        <HomeMatchmakerLink href="/network-matchmaker">Go to matchmaker</HomeMatchmakerLink>
      </HomeMatchmakerContainer>
    </Container>
  );
};

export default HomeMatchmakerBanner;
