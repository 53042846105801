import * as React from 'react';

import Filters from '../Filters';
import {
  Container,
  HomeAgenda,
  HomeAgendaContainer,
  HomeAgendaSectionTitle,
  HomeAgendaMoreContainer,
  HomeAgendaMoreButton,
  HomeAgendaMoreArrow,
  HomeAgendaMore,
  HomeAgendaEventContainer,
  HomeAgendaEventGroup,
  HomeAgendaEvent,
  HomeAgendaImg,
  HomeAgendaInfoContainer,
  HomeDateContainer,
  HomeDate,
  HomeTitle,
  HomeTitleImg,
  HomeAgendaInfo,
  HomeAgendaNewEventContainer,
  HomeAgendaNewEventButton,
} from './styles';

import { Logo, MenuButton } from '../../utils/theme';

import NextArrowImg from './next-arrow.png';

import TitleHoverImg1 from './title-hover-1.png';
import { contentFetch } from '../../lib/content-fetcher';
import { groupBySize, openNewTab } from '../../lib/utils';

const tranformFilters = (result = []) => result.map((item) => ({ id: item._id, text: item.name }));

const HomeAgendaComponent = (props) => {
  const [events, setEvents] = React.useState([]);
  const [filters, setFilters] = React.useState([]);
  const [filterSelected, setFilterSelected] = React.useState(null);
  const [partitionIndex, setPartitionIndex] = React.useState(0);

  const getEvents = (query = '') => {
    contentFetch({
      pathname: '/events',
      setState: setEvents,
      query,
    });
  };

  React.useEffect(() => {
    contentFetch({
      pathname: `/event-types`,
      setState: setFilters,
      transform: tranformFilters,
    });
  }, []);

  React.useEffect(() => {
    if (filterSelected) {
      getEvents(`_sort=dateFrom:asc&event_type.id=${filterSelected}`);
    } else {
      getEvents('_sort=dateFrom:asc');
    }
  }, [filterSelected]);

  const partionedEvents = groupBySize(events, 4);
  return (
    <Container>
      <Logo />
      <MenuButton menuIsShown={props.menuIsShown} />
      <HomeAgenda>
        <Filters title="AGENDA" filters={filters} apply={setFilterSelected} />
        <HomeAgendaContainer>
          <HomeAgendaNewEventContainer>
            <HomeAgendaSectionTitle>Happening now</HomeAgendaSectionTitle>
            <HomeAgendaNewEventButton
              href="https://forms.office.com/pages/responsepage.aspx?id=7fXh3mO9n0qEMm9T3CKab_PloOu5t5xBiLTJN1sRg4BUNU1XMURDNVo3UEpHM0tNUEkyOUdMREVQMi4u"
              target="_blank"
              rel="noreferrer"
            >
              Submit your event
            </HomeAgendaNewEventButton>
          </HomeAgendaNewEventContainer>
          <HomeAgendaEventContainer>
            <HomeAgendaEventGroup>
              {partionedEvents.slice(0, partitionIndex + 1).map((sliceOfEvents) =>
                sliceOfEvents.map((event) => (
                  <HomeAgendaEvent key={event._id} onClick={() => openNewTab(event?.link)}>
                    <HomeAgendaImg
                      src={event?.image[0]?.formats?.medium?.url || event?.image[0]?.url}
                      loading="lazy"
                    />
                    <HomeAgendaInfoContainer>
                      <HomeDateContainer>
                        <HomeDate>
                          {event.dateFrom}
                          {event.dateTo ? '—' : null}
                        </HomeDate>
                        {event.dateTo ? <HomeDate>—{event.dateTo}</HomeDate> : null}
                      </HomeDateContainer>
                      <HomeTitle>
                        {event?.name}
                        <HomeTitleImg src={TitleHoverImg1} />
                      </HomeTitle>
                      <HomeAgendaInfo>{event?.moreInfo}</HomeAgendaInfo>
                    </HomeAgendaInfoContainer>
                  </HomeAgendaEvent>
                )),
              )}
            </HomeAgendaEventGroup>
          </HomeAgendaEventContainer>
          {partitionIndex + 1 < partionedEvents.length && (
            <HomeAgendaMoreContainer>
              <HomeAgendaMoreButton onClick={() => setPartitionIndex(partitionIndex + 1)}>
                <HomeAgendaMoreArrow src={NextArrowImg}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 44 44"
                    style={{ width: '100%', height: '100%' }}
                  >
                    <g fill="none" fill-rule="evenodd">
                      <path d="M0 44V0h44v44z" />
                      <g stroke="currentColor" stroke-linecap="round" stroke-width="2">
                        <path d="M16.919 11 29.5 23.541M29.544 23.537 16.5 36.581" />
                      </g>
                    </g>
                  </svg>
                </HomeAgendaMoreArrow>
                <HomeAgendaMore>Show More events to the right</HomeAgendaMore>
              </HomeAgendaMoreButton>
            </HomeAgendaMoreContainer>
          )}
        </HomeAgendaContainer>
      </HomeAgenda>
    </Container>
  );
};

export default HomeAgendaComponent;
