import * as React from 'react';
import loadable from '@loadable/component';

import SEO from '../components/SEO';
import HomeHeader from '../components/HomeHeader';
import HomeCAN from '../components/HomeCAN';
import HomeImpact from '../components/HomeImpact';
import HomeAgenda from '../components/HomeAgenda';
import HomeMatchmakerBanner from '../components/HomeMatchmakerBanner';
import { contentFetch } from '../lib/content-fetcher';

const Header = loadable(() => import('../components/Header'));
const Footer = loadable(() => import('../components/Footer'));
const HomePartnerships = loadable(() => import('../components/HomePartnerships'));

const IndexPage = ({ path }) => {
  const [menuIsShown, showMenu] = React.useState(false);
  const [posts, setPosts] = React.useState([]);
  const [homePage, setHomePage] = React.useState([]);
  const [showRegister, setShowRegister] = React.useState(false);

  React.useEffect(() => {
    contentFetch({
      pathname: `/posts?homeThisMonth=true&homeFeaturedArticle=true&_sort=published_at:DESC`,
      setState: setPosts,
    });
    contentFetch({
      pathname: `/homepage`,
      setState: setHomePage,
    });
  }, []);
  React.useEffect(() => {
    if (path === '/signup') setShowRegister(true);
  }, [path]);

  return (
    <main>
      <SEO></SEO>
      <Header menuIsShown={menuIsShown} showMenu={showMenu} showRegister={showRegister} white />
      <HomeHeader menuIsShown={menuIsShown} backgroundImage={homePage?.heroImage} />
      <HomeCAN menuIsShown={menuIsShown} video={homePage?.homeVideo} />
      <HomeMatchmakerBanner></HomeMatchmakerBanner>
      <HomeImpact menuIsShown={menuIsShown} posts={posts} />
      <HomePartnerships menuIsShown={menuIsShown} />
      <HomeAgenda menuIsShown={menuIsShown} />
      <Footer menuIsShown={menuIsShown} path={path} />
    </main>
  );
};

export default IndexPage;
