import * as React from 'react';
import ReactPlayer from 'react-player';

import {
  HomeCAN,
  HomeCANContainer,
  HomeCANCol,
  HomeCANBigTitle,
  HomeCANSmallTitle,
  HomeCANDesc,
  HomeCANVideoImg,
} from './styles';
import { HomeMatchmakerLink } from '../HomeMatchmakerBanner/styles';

import VideoImg from './video_thumbnail.jpg';
import { Logo, MenuButton } from '../../utils/theme';
import PlayButton from '../PlayButton';

const HomeCANComponent = (props) => {
  return (
    <HomeCAN>
      <Logo />
      <MenuButton menuIsShown={props.menuIsShown} />
      <HomeCANContainer>
        <HomeCANCol>
          <HomeCANBigTitle>Comm—unity Arts Network</HomeCANBigTitle>
          <ReactPlayer
            url={props.video?.url}
            controls
            light={VideoImg}
            wrapper={HomeCANVideoImg}
            playIcon={<PlayButton />}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload',
                  disablepictureinpicture: 'true',
                },
              },
              youtube: {
                embedOptions: {
                  host: 'https://www.youtube-nocookie.com',
                },
              },
            }}
          />
        </HomeCANCol>
        <HomeCANCol>
          <HomeCANSmallTitle>WE ARE CAN</HomeCANSmallTitle>
          <HomeCANDesc>
            Research conducted by{' '}
            <a target="_blank" href="https://www.fas.at/" rel="noreferrer noopener">
              FASresearch
            </a>{' '}
            into how to increase social impact through the arts with 2075 organisations across 92
            countries established that 'Community Arts is a global community of ideas but not yet a
            global community network'. As a result of these findings,{' '}
            <a
              target="_blank"
              href="https://www.porticus.com/en/our-hubs/community-arts-lab/"
              rel="noreferrer noopener"
            >
              Community Arts Lab- Porticus
            </a>{' '}
            &{' '}
            <a target="_blank" href="https://www.hiltifoundation.org/" rel="noreferrer noopener">
              Hilti Foundation
            </a>{' '}
            joined hands to form Community Arts Network (CAN).
            <br />
            <br />
            CAN was co-created by visionaries who believe that the transformative power of the arts
            can be engaged for social impact. It is a global platform that aims to enable, engage
            and empower individuals, organisations and communities through arts and unlikely
            alliances to generate meaningful change and shape a humane future, together.
            <br />
            <br />
            Learn more about how we work to achieve these goals, how one can become a member of CAN,
            and how CAN benefits members and the global arts for social impact sector as a whole:
          </HomeCANDesc>
          <HomeMatchmakerLink
            style={{ marginTop: '1rem' }}
            href="/Discover_CAN.pdf"
            target="_blank"
          >
            Discover CAN (PDF)
          </HomeMatchmakerLink>
        </HomeCANCol>
      </HomeCANContainer>
    </HomeCAN>
  );
};

export default HomeCANComponent;
