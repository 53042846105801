import styled from 'styled-components';
import { COLORS, BREAKPOINTS, H2, H4, Ps } from '../../utils/theme';

import TextureImg1 from '../../images/textures/1.png';

export const Container = styled.div`
  position: relative;
`;

export const HomeAgenda = styled.section`
  display: flex;
  flex-direction: column;
`;

export const HomeAgendaContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  box-shadow: inset 0px 32px 64px 0px rgba(0, 0, 0, 0.05);

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding: 50px 0;
  }
`;

export const HomeAgendaSectionTitle = styled.h6`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: ${COLORS.BLACK};

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    text-align: center;
    padding: 0;
    text-transform: uppercase;
  }
`;

export const HomeAgendaMoreContainer = styled.div`
  padding-left: 16px;

  @media (min-width: ${BREAKPOINTS.PHABLET}px) {
    padding-left: 50px;
  }
  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    padding-left: 60px;
  }
`;
export const HomeAgendaMoreButton = styled.button`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border-radius: 3rem;
  padding: 0.3rem 1.4rem;

  border: 1.5px solid ${COLORS.BLUE};
  color: ${COLORS.BLUE};

  &:focus-visible {
    outline: 2px solid ${COLORS.BLUE};
  }
  &:hover {
    opacity: 0.7;
  }
`;

export const HomeAgendaMoreArrow = styled.div`
  height: 2.5rem;
  height: 2.5rem;
  margin-left: -0.75rem;
  margin-right: 0.5rem;
`;

export const HomeAgendaMore = styled.span`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 600;
`;

export const HomeAgendaEventContainer = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  position: relative;

  &:before {
    opacity: 1;
    position: absolute;
    display: block;
    content: '';
    left: 35%;
    bottom: 0;
    width: 370px;
    height: 400px;
    background-image: url(${TextureImg1});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
`;

export const HomeAgendaEventGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 50px;

  &:first-child {
    padding-left: 60px;

    @media (max-width: ${BREAKPOINTS.TABLET}px) {
      padding-left: 50px;
    }

    @media (max-width: ${BREAKPOINTS.PHABLET}px) {
      padding-left: 18px;
    }
  }
`;

export const HomeAgendaImg = styled.img`
  width: 353px;
  height: 197px;
  object-fit: cover;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 264px;
    height: 197px;
  }
`;

export const HomeTitleImg = styled.img`
  display: none;
  position: absolute;

  width: 196px;
  height: 137px;
  left: -60px;
  top: -60px;
`;

export const HomeDate = styled(H2)`
  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 32px;
    display: inline-block;
  }
`;

export const HomeTitle = styled(H4)`
  position: relative;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 20px;
  }
`;

export const HomeAgendaInfo = styled(Ps)`
  display: none;
  color: ${COLORS.WHITE};

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    display: inline-block;
    color: ${COLORS.BLACK};
  }
`;

export const HomeAgendaEvent = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 30px;
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.BLACK};
  height: 500px;
  width: 355px;
  transition: all 0.5s;
  position: relative;
  margin-bottom: 80px;
  cursor: pointer;

  @media (min-width: ${BREAKPOINTS.PHABLET - 1}px) {
    &:hover {
      background-color: ${COLORS.BLACK};
      box-shadow: 0px 40px 30px 0px rgba(0, 0, 0, 0.15);

      ${HomeAgendaImg} {
        display: none;
      }

      ${HomeDate},
      ${HomeTitle},
            ${HomeAgendaInfo} {
        color: ${COLORS.WHITE};
      }

      ${HomeTitle} {
        margin-top: 70px;
        text-decoration: underline;
      }

      ${HomeAgendaInfo} {
        display: inline-block;
      }

      ${HomeTitleImg} {
        display: inline-block;
      }
    }
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    margin-bottom: 30px;
    padding-right: 16px;
    height: 441px;
    width: 264px;
  }
`;

export const HomeAgendaInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0 10px 30px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding: 16px;
    overflow-y: auto;
  }
`;

export const HomeDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    display: initial;
  }
`;

export const HomeAgendaNewEventContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;

  & > *:not(:first-child) {
    margin-left: 0;
    margin-top: 1rem;
  }

  @media (min-width: ${BREAKPOINTS.PHABLET}px) {
    padding-left: 50px;
    flex-direction: row;

    & > *:not(:first-child) {
      margin-top: 0;
      margin-left: 1.5rem;
    }
  }
  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    padding-left: 60px;
  }
`;
export const HomeAgendaNewEventButton = styled.a`
  background-color: ${COLORS.BLUE};
  color: ${COLORS.WHITE};
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.5;
  text-decoration: none;
  padding: 0.6rem 1.8rem 0.8rem;
  border-radius: 35.5px;
  display: inline-block;
`;
