import * as React from 'react';
import useScrollPosition from '@react-hook/window-scroll';

import {
  HomeHeader,
  Logo,
  MenuButton,
  HomeHeaderText,
  ImgArtLines,
  ImgConnectLines,
  ImgExistLines,
} from './styles';

const HomeHeaderComponent = (props) => {
  const scrollY = useScrollPosition(60);
  const hide = scrollY < 120;

  const headerBackground = props?.backgroundImage?.url;

  return (
    <HomeHeader src={headerBackground}>
      <Logo visible={!hide} white useViewportHeight />
      <MenuButton visible={!hide} menuIsShown={props.menuIsShown} white useViewportHeight />
      <HomeHeaderText>
        To give a name to the challenges ahead,
        <br />
        to apply art as a force for change,
        <br />
        to inspire and connect
        <br />
        through the unexpected
        <br />
        <br />— that's why we exist.
        <ImgArtLines fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 165">
          <defs>
            <filter id="noise1">
              <feTurbulence baseFrequency="0.3" />
              <feDisplacementMap in="SourceGraphic" scale="11" />
            </filter>
          </defs>
          <path
            pathLength="1"
            filter="url(#noise1)"
            d="M80 5C38.8-6.6 2 38 11 66.5c-40 60 56 115 82 73.5 43.5 30.5 104-51.5 62.5-78.5 13-48-51.5-69-75.5-56.5Z"
            stroke="currentColor"
            strokeWidth="6"
          />
        </ImgArtLines>
        <ImgConnectLines fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 347 50">
          <path
            pathLength="1"
            filter="url(#noise1)"
            d="M9 28.84c7.5-5 23.5-14.4 27.5-12 5 3-19.31 24.41-3.5 16.5 24-12 44.5-28 43-20-.7 3.75-22.5 23.5-8.5 15.5s48.72-23.48 46-17.5c-5 11-26.68 27.68-8 19 14-6.5 44.94-24.58 40.5-12-3 8.5-20.5 26-6.5 19.5s39.5-20 42.5-17-22.5 24.5-9 18 39.5-20 48.5-19-21 23.5-6.5 16.5 36.5-20.5 48-20.5c16 0-36.5 32-6.5 18.5S304 9.84 304 15.84s-28.5 31-8 20.5 34-21.5 39-19-1 9-5 13"
            stroke="currentColor"
            strokeWidth="5"
          />
        </ImgConnectLines>
        <ImgExistLines fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 211 277">
          <path
            pathLength="1"
            filter="url(#noise1)"
            d="M34 70c-5.5-6.83-17.6-24.4-22-40m90 18.5c-2-9.67-5.4-32.3-3-45.5m61 56.5c4.67-9.67 16.2-31.1 25-39.5m-17.5 166c11.83 13.83 35.5 45.9 35.5 63.5m-160.5-61c-8 7.67-26 28.1-34 48.5m92.5-58c-1 29.5-3.2 88.8-4 90"
            stroke="currentColor"
            strokeWidth="6"
          />
        </ImgExistLines>
      </HomeHeaderText>
    </HomeHeader>
  );
};

export default HomeHeaderComponent;
